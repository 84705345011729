// CommitChart.ts
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Bar, mixins } from "vue-chartjs";

@Component({
  extends: Bar, // this is important to add the functionality to your component
  mixins: [mixins.reactiveProp]
})
export default class MaChart extends Mixins(mixins.reactiveProp, Bar) {
  @Prop({ required: true }) Options: any;
  public data1: any = [
    40,
    20,
    12,
    39,
    10,
    40,
    39,
    14,
    40,
    20,
    12,
    11,
    10,
    40,
    39,
    26,
    40,
    20,
    12,
    11,
    10,
    40,
    39,
    31,
    40,
    20,
    12,
    11,
    30,
    23]

  public data2: any[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30"]


  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      this.chartData,
      this.Options
    );
    // this.renderChart(
    //   {
    //     labels: [
    //       1,
    //       "2",
    //       "3",
    //       "4",
    //       "5",
    //       "6",
    //       "7",
    //       "8",
    //       "9",
    //       "10",
    //       "11",
    //       "12",
    //       "13",
    //       "14",
    //       "15",
    //       "16",
    //       "17",
    //       "18",
    //       "19",
    //       "20",
    //       "21",
    //       "22",
    //       "23",
    //       "24",
    //       "25",
    //       "26",
    //       "27",
    //       "28",
    //       "29",
    //       "30"
    //     ],
    //     datasets: [
    //       {
    //         label: "Appointments",
    //         data: [1,2,3,4,3],
    //         backgroundColor: "#03B4BC"
    //       }
    //     ]
    //   },
    //   this.Options
    // );
  }
}