








































































import { Vue, Component } from "vue-property-decorator";
import MaChart from "./MaChart";
import { Chart } from "chart.js";
import DoctorAppointmentService from "../../service/DoctorAppointmentService";
import {IDoctorAppointmentChart} from '../../../doctor-app/models/DoctorAppointmentChart';
@Component({
  components: {
    // BarChart,
    MaChart,
  },
})
export default class MonthlyAppointments extends Vue {
  public Year: any = new Date().getFullYear();
  public doctorAppointmentChart:IDoctorAppointmentChart[]=[];
  public apptCount:any=[];
  public apptDay:any=[];
  public currentMonth:number=new Date().getMonth();
  
  public Months: any = [
    { month: "Jan " + this.Year, active: false },
    { month: "Feb " + this.Year, active: false },
    { month: "Mar " + this.Year, active: false },
    { month: "Apr " + this.Year, active: false },
    { month: "May " + this.Year, active: false },
    { month: "Jun " + this.Year, active: false },
    { month: "Jul " + this.Year, active: false },
    { month: "Aug " + this.Year, active: false },
    { month: "Sep " + this.Year, active: true },
    { month: "Oct " + this.Year, active: false },
    { month: "Nov " + this.Year, active: false },
    { month: "Dec " + this.Year, active: false },
  ];
   public data1:any=[
    40,
    20,
    12,
    39,
    10,
    40,
    39,
    14,
    40,
    20,
    12,
    11,
    10,
    40,
    39,
    26,
    40,
    20,
    12,
    11,
    10,
    40,
    39,
    31,
    40,
    20,
    12,
    11,
    30,
    23]
    public data2:any[]=[
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30"]
    public options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };
  public chartdata={
    labels: this.data2,
    datasets: [
      {
        label: "Appointments",
        data: this.data1
        ,
        backgroundColor: "#03B4BC"
      }
    ]
  };
  created()
      {
        this.toggleMonth(new Date().getMonth());
      }

  toggleMonth(i: any) { 
    // this.apptCount=[];
    // this.apptCount=[];
    // DoctorAppointmentService.getChartDate(i+1).then((res) => {
    //    this.doctorAppointmentChart=res.data;       
    // });
    // alert(i);
    this.Months.forEach((element: any) => {
      element.active = false;
    });
    
    this.Months[i].active = true;
    this.GetChartData(i);
    // this.chartdata = {
    //   labels: this.apptDay,
    //   datasets: [
    //     {
    //       label: "Appointments",
    //       data: this.apptCount
    //       ,
    //       backgroundColor: "#03B4BC"
    //     }
    //   ]
    // };
 }
  GetChartData(i: any){
     //alert(i);
    this.apptCount=[];
    this.apptDay=[];
    DoctorAppointmentService.getChartDate(i+1).then((res) => {
      this.doctorAppointmentChart=res.data;     
         // console.log(this.doctorAppointmentChart); 
      for (let index = 0; index < this.doctorAppointmentChart.length; index++) {
      for (let [key, value] of Object.entries(this.doctorAppointmentChart[index])) {
      if(key=="count"){
        this.apptCount.push(value);
        }
        if(key=="dayNumber"){
        this.apptDay.push(value);
        }
      } 
    }

      this.chartdata = {
      labels: this.apptDay,
      datasets: [
        {
          label: "Appointments",
          data: this.apptCount
          ,
          backgroundColor: "#03B4BC"
        }
      ]
    };

    });
    //console.log(this.doctorAppointmentChart);
    // for (let index = 0; index < this.doctorAppointmentChart.length; index++) {
    //   for (let [key, value] of Object.entries(this.doctorAppointmentChart[index])) {
    //   if(key=="count"){
    //     this.apptCount.push(value);
    //       // console.log(value);
    //     }
    //     if(key=="dayNumber"){
    //     this.apptDay.push(value);
    //       //console.log(value);
    //     }
    //   } 
    // }

    //     this.chartdata = {
    //   labels: this.apptDay,
    //   datasets: [
    //     {
    //       label: "Appointments",
    //       data: this.apptCount
    //       ,
    //       backgroundColor: "#03B4BC"
    //     }
    //   ]
    // };
  }
}
