







import { Vue, Component } from 'vue-property-decorator';
import TodayDashboard from './TodayDashboard.vue';
import MonthlyAppointments from './MonthlyAppointments.vue';

@Component({
  components: {
    TodayDashboard,
    MonthlyAppointments
  }
})
export default class Home extends Vue {}
